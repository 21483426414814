<div *ngIf="{
    item: (item$ | async),
    price: (price$ | async),
    stock: (stock$ | async),
    details: (details$ | async),
    pricelist: (pricelist$ | async),
    config: (config$ |async)
  } as data">
  <div class="flex flex-col gap-4">

    <div class="flex flex-col gap-2 lg:gap-3 lg:flex-row items-start mt-4">
      <ng-container *ngIf="data?.item?.thumbnail | media as thumbnail">
        <div class="flex flex-col max-h-[12.75rem] w-full lg:w-1/4 border items-center justify-center bg-surface lg:justify-start h-auto border-solid broder-separator rounded" *ngIf="data?.item?.thumbnail && thumbnail" (click)="openImageDialog(thumbnail)">
          <img [src]="thumbnail" class="w-auto h-auto max-h-[12.5rem] rounded" />
        </div>
      </ng-container>

      <div class="flex flex-col w-full gap-2" [ngClass]="data?.item?.thumbnail ? 'lg:w-3/4' : ''">
        <div class="flex flex-col px-3 py-2 border border-solid boder-separator rounded" *ngIf="data.item?.number">
          <span class="text-sm font-medium text-light">{{ '#ARTIKELINFO.PAGES.DETAIL.ITEM_NUMBER' | translate }}</span>
          <span class="text-base font-semibold mb-0">{{ data.item?.number }}</span>
        </div>

        <div class="flex flex-col px-3 py-2 border border-solid boder-separator rounded">
          <span class="text-sm font-medium text-light">{{ '#ARTIKELINFO.PAGES.DETAIL.ARTBEZ1' | translate }}</span>
          <span class="text-base font-semibold mb-0">{{ data.item?.title1 }}</span>
        </div>

        <div class="flex flex-col px-3 py-2 border border-solid boder-separator rounded" *ngIf="data.item?.title2">
          <span class="text-sm font-medium text-light">{{ '#ARTIKELINFO.PAGES.DETAIL.ARTBEZ2' | translate }}</span>
          <span class="text-base font-semibold mb-0">{{ data.item?.title2 }}</span>
        </div>
      </div>
    </div>

    <div class="flex flex-col mt-5" *ngIf="data?.pricelist && data?.pricelist?.tieredPricelist && (data?.pricelist?.tieredPricelist?.length ?? 0) > 0">
      <h6 class="font-semibold text-light text-sm mb-1 uppercase leading-wide tracking-wide">{{ '#ARTIKELINFO.PAGES.DETAIL.PRICES.TITLE' | translate }}</h6>

      <div class="full-width flex flex-col">
        <cdk-accordion class="flex flex-col" [multi]="true">
          <cdk-accordion-item
            #accordionItem="cdkAccordionItem"
            *ngFor="let stockEntry of (data.pricelist?.tieredPricelist || []); let i = index"
            role="button"
            tabindex="0"
            [expanded]="true"
            [attr.id]="'accordion-header-' + i"
            [attr.aria-expanded]="accordionItem.expanded"
            [attr.aria-controls]="'accordion-body-' + i"
            class="flex flex-col w-full  border-b border-b-solid border-b-separator">
            <div class="pt-2 pb-2 px-4 font-semibold flex justify-between items-center" (click)="accordionItem.toggle()">
              {{ stockEntry?.priceLevel?.title }}
              <div class="text-lighter">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" class="w-5 h-5 text-light" *ngIf="!accordionItem.expanded">
                  <path fill-rule="evenodd" d="M5.23 7.21a.75.75 0 011.06.02L10 11.168l3.71-3.938a.75.75 0 111.08 1.04l-4.25 4.5a.75.75 0 01-1.08 0l-4.25-4.5a.75.75 0 01.02-1.06z" clip-rule="evenodd" />
                </svg>

                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" class="w-5 h-5 text-light" *ngIf="accordionItem.expanded">
                  <path fill-rule="evenodd" d="M14.77 12.79a.75.75 0 01-1.06-.02L10 8.832 6.29 12.77a.75.75 0 11-1.08-1.04l4.25-4.5a.75.75 0 011.08 0l4.25 4.5a.75.75 0 01-.02 1.06z" clip-rule="evenodd" />
                </svg>
              </div>
            </div>

            <div [style.display]="accordionItem.expanded ? 'block' : 'none'" class="bg-white">
              <div class="g gtc-4 gap-8 w-full px-4 py-2 w-full border-t border-t-solid border-t-separator bg-gray-100">
                <div class="text-[0.825rem] font-semibold text-right">{{ '#ARTIKELINFO.PAGES.DETAIL.PRICES.AMOUNT' | translate }}</div>
                <div class="text-[0.825rem] font-semibold">{{ '#ARTIKELINFO.PAGES.DETAIL.PRICES.UNIT' | translate }}</div>
                <div class="text-[0.825rem] font-semibold">{{ '#ARTIKELINFO.PAGES.DETAIL.PRICES.PER' | translate }}</div>
                <div class="text-[0.825rem] font-semibold flex items-center justify-end text-right text-right">{{ '#ARTIKELINFO.PAGES.DETAIL.PRICES.PRICE' | translate }}</div>
              </div>
              <ng-container *ngFor="let tierPrice of stockEntry.tieredPrices; let last = last">
                <div class="g gtc-4 gap-8 w-full px-4 py-3 border-t border-t-solid border-t-separator">
                  <span class="text-right">{{ tierPrice.amount }}</span>
                  <span>{{ tierPrice.unit.name }}</span>
                  <span>{{ tierPrice?.priceUnit ?? 1 }}</span>
                  <span class="text-right">{{ tierPrice.gross ?? 0 | currency:tierPrice.currency:'symbol-narrow':'1.2-4' }}</span>
                </div>
              </ng-container>
            </div>
          </cdk-accordion-item>
        </cdk-accordion>

        <ng-container *ngIf="data?.pricelist?.discountTieredPricelist && (data?.pricelist?.discountTieredPricelist || []).length > 0 && (((data?.pricelist?.discountTieredPricelist ?? [])[0]?.discountTieredPrices?.length ?? 0) > 0)">
          <cdk-accordion class="flex flex-col" [multi]="true">
            <cdk-accordion-item
              #accordionItem="cdkAccordionItem"
              *ngFor="let stockEntry of (data.pricelist?.discountTieredPricelist || []); let i = index"
              role="button"
              tabindex="0"
              [expanded]="true"
              [attr.id]="'accordion-header-' + i"
              [attr.aria-expanded]="accordionItem.expanded"
              [attr.aria-controls]="'accordion-body-' + i"
              class="flex flex-col w-full border-b border-b-solid border-b-separator">
              <div class="pt-2 pb-2 px-4 flex items-center justify-between bg-danger-100" (click)="accordionItem.toggle()">
                <div class="flex items-center gap-2">
                  <span [innerHTML]="stockEntry?.discount?.title || stockEntry?.discount?.name"></span><!--<span class="text-sm font-normal" *ngIf="(stockEntry.discountTieredPrices?.length || 0) > 0 && stockEntry.discountTieredPrices[0]?.from && stockEntry.discountTieredPrices[0]?.to">({{stockEntry.discountTieredPrices[0]?.from | format:'DATE.DEFAULT'}} - {{stockEntry.discountTieredPrices[0]?.to | date:'dd.MM.yyyy' }})</span>-->
                </div>
                <div class="text-lighter">
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" class="w-5 h-5 text-light" *ngIf="!accordionItem.expanded">
                    <path fill-rule="evenodd" d="M5.23 7.21a.75.75 0 011.06.02L10 11.168l3.71-3.938a.75.75 0 111.08 1.04l-4.25 4.5a.75.75 0 01-1.08 0l-4.25-4.5a.75.75 0 01.02-1.06z" clip-rule="evenodd" />
                  </svg>

                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" class="w-5 h-5 text-light" *ngIf="accordionItem.expanded">
                    <path fill-rule="evenodd" d="M14.77 12.79a.75.75 0 01-1.06-.02L10 8.832 6.29 12.77a.75.75 0 11-1.08-1.04l4.25-4.5a.75.75 0 011.08 0l4.25 4.5a.75.75 0 01-.02 1.06z" clip-rule="evenodd" />
                  </svg>
                </div>
              </div>

              <div [style.display]="accordionItem.expanded ? 'block' : 'none'" class="bg-white">
                <div class="g gtc-4 gap-8 w-full px-4 py-2 w-full border-t border-t-solid border-t-separator bg-gray-100">
                  <div class="text-[0.825rem] font-semibold text-right">{{ '#ARTIKELINFO.PAGES.DETAIL.PRICES.AMOUNT' | translate }}</div>
                  <div class="text-[0.825rem] font-semibold">{{ '#ARTIKELINFO.PAGES.DETAIL.PRICES.UNIT' | translate }}</div>
                  <div class="text-[0.825rem] font-semibold">{{ '#ARTIKELINFO.PAGES.DETAIL.PRICES.PER' | translate }}</div>
                  <div class="text-[0.825rem] font-semibold flex items-center justify-end text-right text-right">{{ '#ARTIKELINFO.PAGES.DETAIL.PRICES.PRICE' | translate }}</div>
                </div>
                <ng-container *ngFor="let tierPrice of stockEntry.discountTieredPrices; let last = last">
                  <div class="g gtc-4 gap-8 w-full px-4 py-3 border-t border-t-solid border-t-separator bg-green-100">
                    <span class="text-right self-center">{{ tierPrice.amount }}</span>
                    <span class="self-center">{{ tierPrice.unit.name }}</span>
                    <span>{{ tierPrice?.priceUnit ?? 1 }}</span>
                    <div class="flex text-right" [ngClass]="tierPrice.gross === 0 ? 'items-center justify-end' : 'flex-col justify-center items-end'">
                      <span class="font-semibold text-right" *ngIf="tierPrice.gross !== 0">{{ tierPrice.gross ?? 0 | currency:tierPrice.currency }}</span>
                      <div *ngIf="tierPrice?.discountPercentage && (tierPrice?.discountPercentage?.length ?? 0) > 0" class="flex items-center">
                        <ng-container>
                        <span
                          class="text-right"
                          [ngClass]="tierPrice.gross === 0 ? 'font-semibold flex gap-1' : 'font-normal text-xs text-light flex gap-1'"
                          *ngFor="let percentage of tierPrice.discountPercentage; let last = last">
                          {{ percentage | number: '1.2-2' }}%<ng-container *ngIf="!last">&nbsp;+&nbsp;</ng-container>
                        </span>
                        </ng-container>
                      </div>
                    </div>
                  </div>
                </ng-container>
              </div>
            </cdk-accordion-item>
          </cdk-accordion>
        </ng-container>
      </div>
    </div>

    <cdk-accordion>
      <cdk-accordion-item
        *ngIf="data?.details?.itemVkforg as vkforgs;"
        role="button"
        tabindex="0"
        #accordionItem="cdkAccordionItem"
        [expanded]="data?.config?.listungExpanded || false"
        [attr.aria-expanded]="accordionItem.expanded"
        class="flex flex-col w-full full-width  border-b border-b-solid border-b-separator">
        <div class="pt-2 pb-1 px-4 font-semibold flex justify-between items-center" (click)="accordionItem.toggle()">
          <h6 class="font-semibold text-light text-sm mb-1 uppercase leading-wide tracking-wide">{{ '#ARTIKELINFO.PAGES.DETAIL.VKFORG.LISTUNG' | translate }}</h6>
          <div class="text-lighter">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" class="w-5 h-5 text-light" *ngIf="!accordionItem.expanded">
              <path fill-rule="evenodd" d="M5.23 7.21a.75.75 0 011.06.02L10 11.168l3.71-3.938a.75.75 0 111.08 1.04l-4.25 4.5a.75.75 0 01-1.08 0l-4.25-4.5a.75.75 0 01.02-1.06z" clip-rule="evenodd" />
            </svg>

            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" class="w-5 h-5 text-light" *ngIf="accordionItem.expanded">
              <path fill-rule="evenodd" d="M14.77 12.79a.75.75 0 01-1.06-.02L10 8.832 6.29 12.77a.75.75 0 11-1.08-1.04l4.25-4.5a.75.75 0 011.08 0l4.25 4.5a.75.75 0 01-.02 1.06z" clip-rule="evenodd" />
            </svg>
          </div>
        </div>

        <div [style.display]="accordionItem.expanded ? 'block' : 'none'" class="bg-white">
          <div class="flex px-4 py-2 items-center justify-between w-full border-b border-b-solid border-b-separator border-t border-t-solid border-t-separator bg-gray-100">
            <div class="text-[0.825rem] font-semibold">{{ '#ARTIKELINFO.PAGES.DETAIL.VKFORG.VKFORG' | translate }}</div>
            <div class="text-[0.825rem] font-semibold flex items-center justify-end text-right">{{ '#ARTIKELINFO.PAGES.DETAIL.VKFORG.LISTUNG' | translate }}</div>
          </div>
          <div *ngFor="let vkforg of vkforgs" class="flex px-4 py-3 items-center justify-between w-full border-b border-b-solid border-b-separator">
            <div class="">{{ vkforg.vkforg?.vkforg }}</div>
            <div class="font-semibold flex items-center justify-end text-right">{{ vkforg.listing.kzgelistet }} - {{ vkforg.listing.title }}</div>
          </div>
        </div>
      </cdk-accordion-item>
    </cdk-accordion>

    <cdk-accordion *ngIf="data?.stock as stock">
      <cdk-accordion-item
        role="button"
        tabindex="0"
        #accordionItem="cdkAccordionItem"
        [expanded]="data?.config?.lagerstaendeExpanded || false"
        [attr.aria-expanded]="accordionItem.expanded"
        class="flex flex-col w-full full-width  border-b border-b-solid border-b-separator">
        <div class="pt-2 pb-1 px-4 font-semibold flex justify-between items-center" (click)="accordionItem.toggle()">
          <h6 class="font-semibold text-light text-sm mb-1 uppercase leading-wide tracking-wide">{{ '#ARTIKELINFO.PAGES.DETAIL.STOCK.TITLE' | translate }}</h6>
          <div class="text-lighter">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" class="w-5 h-5 text-light" *ngIf="!accordionItem.expanded">
              <path fill-rule="evenodd" d="M5.23 7.21a.75.75 0 011.06.02L10 11.168l3.71-3.938a.75.75 0 111.08 1.04l-4.25 4.5a.75.75 0 01-1.08 0l-4.25-4.5a.75.75 0 01.02-1.06z" clip-rule="evenodd" />
            </svg>

            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" class="w-5 h-5 text-light" *ngIf="accordionItem.expanded">
              <path fill-rule="evenodd" d="M14.77 12.79a.75.75 0 01-1.06-.02L10 8.832 6.29 12.77a.75.75 0 11-1.08-1.04l4.25-4.5a.75.75 0 011.08 0l4.25 4.5a.75.75 0 01-.02 1.06z" clip-rule="evenodd" />
            </svg>
          </div>
        </div>

        <div [style.display]="accordionItem.expanded ? 'block' : 'none'" class="bg-white">
          <div class="flex flex-col bg-white border-t border-t-solid border-t-separator">
            <div class="flex px-4 py-2 items-center w-full border-b border-b-solid border-b-separator bg-gray-100">
              <div class="w-2/4 text-[0.825rem] font-semibold">{{ '#ARTIKELINFO.PAGES.DETAIL.STOCK.STOCK' | translate }}</div>
              <div class="w-1/4 text-[0.825rem] font-semibold flex items-center justify-end text-right">{{ '#ARTIKELINFO.PAGES.DETAIL.STOCK.AMOUNT' | translate }}</div>
              <div class="w-1/4 text-[0.825rem] font-semibold flex items-center justify-end text-right">{{ '#ARTIKELINFO.PAGES.DETAIL.STOCK.DISPO' | translate }}</div>
            </div>
            <div
              *ngFor="let stockEntry of stock.stockAmount"
              class="flex px-4 py-3 items-start w-full border-b border-b-solid border-b-separator">
              <div class="w-2/4 flex items-start gap-2">
                <!-- <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6 p-1 cursor-pointer bg-secondary text-secondary-contrast rounded-sm" (click)="showBewegungen(stockEntry, data.item)">
                   <path stroke-linecap="round" stroke-linejoin="round" d="M12 6.042A8.967 8.967 0 006 3.75c-1.052 0-2.062.18-3 .512v14.25A8.987 8.987 0 016 18c2.305 0 4.408.867 6 2.292m0-14.25a8.966 8.966 0 016-2.292c1.052 0 2.062.18 3 .512v14.25A8.987 8.987 0 0018 18a8.967 8.967 0 00-6 2.292m0-14.25v14.25" />
                 </svg> --->

                <!-- <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" class="w-6 h-6 p-1 cursor-pointer bg-secondary text-secondary-contrast rounded-sm" (click)="showBewegungen(stockEntry, data.item)">
                   <path stroke-linecap="round" stroke-linejoin="round" d="M21 12a2.25 2.25 0 00-2.25-2.25H15a3 3 0 11-6 0H5.25A2.25 2.25 0 003 12m18 0v6a2.25 2.25 0 01-2.25 2.25H5.25A2.25 2.25 0 013 18v-6m18 0V9M3 12V9m18 0a2.25 2.25 0 00-2.25-2.25H5.25A2.25 2.25 0 003 9m18 0V6a2.25 2.25 0 00-2.25-2.25H5.25A2.25 2.25 0 003 6v3" />
                 </svg>-->

                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.75" stroke="currentColor" class="w-6 h-6 p-0.5 cursor-pointer border border-primary hover:bg-primary-100 text-primary rounded-sm" (click)="showBewegungen(stockEntry, data.item)">
                  <path stroke-linecap="round" stroke-linejoin="round" d="M9 12h3.75M9 15h3.75M9 18h3.75m3 .75H18a2.25 2.25 0 002.25-2.25V6.108c0-1.135-.845-2.098-1.976-2.192a48.424 48.424 0 00-1.123-.08m-5.801 0c-.065.21-.1.433-.1.664 0 .414.336.75.75.75h4.5a.75.75 0 00.75-.75 2.25 2.25 0 00-.1-.664m-5.8 0A2.251 2.251 0 0113.5 2.25H15c1.012 0 1.867.668 2.15 1.586m-5.8 0c-.376.023-.75.05-1.124.08C9.095 4.01 8.25 4.973 8.25 6.108V8.25m0 0H4.875c-.621 0-1.125.504-1.125 1.125v11.25c0 .621.504 1.125 1.125 1.125h9.75c.621 0 1.125-.504 1.125-1.125V9.375c0-.621-.504-1.125-1.125-1.125H8.25zM6.75 12h.008v.008H6.75V12zm0 3h.008v.008H6.75V15zm0 3h.008v.008H6.75V18z" />
                </svg>

                <div class="flex flex-col gap-0.5">
                  {{ stockEntry.stock?.title || stockEntry.stock?.name }}

                  <div class="flex flex-col gap-0.5 mt-2">
                    @if (data?.config?.showNextOrderDate && stockEntry?.nextOrderDeliveryDate) {
                      <span class="text-light text-sm">{{ '#ARTIKELINFO.PAGES.DETAIL.STOCK.NEXT_DELIVERY' | translate | interpolate: { date: stockEntry?.nextOrderDeliveryDate | format:'DATE.DEFAULT' } }}</span>
                    }

                    <span class="text-light text-sm">{{ '#ARTIKELINFO.PAGES.DETAIL.STOCK.ORDER_AMOUNT' | translate }} {{ (stockEntry?.orders || 0) | number:'1.2-2' }} {{ stockEntry?.itemUnit?.name }}</span>
                  </div>
                </div>
              </div>
              <div class="w-1/4 font-semibold flex items-center justify-end text-right">{{ stockEntry.amount | number:'1.2-2' }} {{ stockEntry?.itemUnit?.name }}</div>
              <div class="w-1/4 font-semibold flex items-center justify-end text-right">{{ ((stockEntry.amount - stockEntry.reserved) + stockEntry.orders) | number:'1.2-2'  }} {{ stockEntry?.itemUnit?.name }}</div>
            </div>
          </div>
        </div>
      </cdk-accordion-item>
    </cdk-accordion>

    <ng-container  *ngIf="data?.details as details">
      <cdk-accordion *ngIf="details?.itemStorage && (details?.itemStorage?.length || 0) > 0">
        <cdk-accordion-item
          role="button"
          tabindex="0"
          #accordionItem="cdkAccordionItem"
          [expanded]="data?.config?.lagerplaetzeExpanded || false"
          [attr.aria-expanded]="accordionItem.expanded"
          class="flex flex-col w-full full-width  border-b border-b-solid border-b-separator">
          <div class="pt-2 pb-1 px-4 font-semibold flex justify-between items-center" (click)="accordionItem.toggle()">
            <h6 class="font-semibold text-light text-sm mb-1 uppercase leading-wide tracking-wide">{{ '#ARTIKELINFO.PAGES.DETAIL.STOCK_LOCATIONS.TITLE' | translate }}</h6>
            <div class="text-lighter">
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" class="w-5 h-5 text-light" *ngIf="!accordionItem.expanded">
                <path fill-rule="evenodd" d="M5.23 7.21a.75.75 0 011.06.02L10 11.168l3.71-3.938a.75.75 0 111.08 1.04l-4.25 4.5a.75.75 0 01-1.08 0l-4.25-4.5a.75.75 0 01.02-1.06z" clip-rule="evenodd" />
              </svg>

              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" class="w-5 h-5 text-light" *ngIf="accordionItem.expanded">
                <path fill-rule="evenodd" d="M14.77 12.79a.75.75 0 01-1.06-.02L10 8.832 6.29 12.77a.75.75 0 11-1.08-1.04l4.25-4.5a.75.75 0 011.08 0l4.25 4.5a.75.75 0 01-.02 1.06z" clip-rule="evenodd" />
              </svg>
            </div>
          </div>

          <div [style.display]="accordionItem.expanded ? 'block' : 'none'" class="bg-white">
            <div class="flex flex-col bg-white"
                 *ngFor="let stockStorageData of details.itemStorage | groupByStock; let first = first"
                 [ngClass]="first ? 'border-t border-t-solid border-t-separator' : ''">
              <div class="flex px-4 py-2 items-center w-full border-b border-b-solid border-b-separator bg-gray-100">
                <div class="w-full text-[0.825rem] font-semibold">{{ stockStorageData.stock?.title || stockStorageData.stock?.name }}</div>
              </div>
              <div *ngFor="let storage of stockStorageData?.storages"
                   class="flex px-4 py-3 items-center justify-between w-full text-sm border-b border-b-solid border-b-separator"
                   [class.!bg-green-50]="storage?.kzlagerplatz === 'H'">
                <div>{{ storage.title }} {{ storage?.name }}</div>
              </div>
            </div>
          </div>
        </cdk-accordion-item>
      </cdk-accordion>

      <!-- <div class="flex flex-col mt-5" *ngIf="details?.itemStorage && (details?.itemStorage?.length || 0) > 0">
         <h6 class="font-semibold text-light text-sm mb-1 uppercase leading-wide tracking-wide">{{ '#ARTIKELINFO.PAGES.DETAIL.STOCK_LOCATIONS.TITLE' | translate }}</h6>
         <div class="full-width flex flex-col bg-white"
              *ngFor="let stockStorageData of details.itemStorage | groupByStock; let first = first"
               [ngClass]="first ? 'border-t border-t-solid border-t-separator' : ''">
           <div class="flex px-4 py-2 items-center w-full border-b border-b-solid border-b-separator bg-gray-100">
             <div class="w-full text-[0.825rem] font-semibold">{{ stockStorageData.stock?.title || stockStorageData.stock?.name }}</div>
           </div>
           <div *ngFor="let storage of stockStorageData?.storages"
                class="flex px-4 py-3 items-center justify-between w-full text-sm border-b border-b-solid border-b-separator"
                [class.!bg-green-50]="storage?.kzlagerplatz === 'H'">
             <div>{{ storage.title }} {{ storage?.name }}</div>
           </div>
         </div>
       </div>-->

      <cdk-accordion *ngIf="details?.itemStorage && (details?.itemStorage?.length || 0) > 0">
        <cdk-accordion-item
          role="button"
          tabindex="0"
          #accordionItem="cdkAccordionItem"
          [expanded]="data?.config?.einheitenExpanded || false"
          *ngIf="data?.details?.itemUnitData as units"
          [attr.aria-expanded]="accordionItem.expanded"
          class="flex flex-col w-full full-width  border-b border-b-solid border-b-separator">
          <div class="pt-2 pb-1 px-4 font-semibold flex justify-between items-center" (click)="accordionItem.toggle()">
            <h6 class="font-semibold text-light text-sm mb-1 uppercase leading-wide tracking-wide">{{ '#ARTIKELINFO.PAGES.DETAIL.UNITS.TITLE' | translate }}</h6>
            <div class="text-lighter">
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" class="w-5 h-5 text-light" *ngIf="!accordionItem.expanded">
                <path fill-rule="evenodd" d="M5.23 7.21a.75.75 0 011.06.02L10 11.168l3.71-3.938a.75.75 0 111.08 1.04l-4.25 4.5a.75.75 0 01-1.08 0l-4.25-4.5a.75.75 0 01.02-1.06z" clip-rule="evenodd" />
              </svg>

              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" class="w-5 h-5 text-light" *ngIf="accordionItem.expanded">
                <path fill-rule="evenodd" d="M14.77 12.79a.75.75 0 01-1.06-.02L10 8.832 6.29 12.77a.75.75 0 11-1.08-1.04l4.25-4.5a.75.75 0 011.08 0l4.25 4.5a.75.75 0 01-.02 1.06z" clip-rule="evenodd" />
              </svg>
            </div>
          </div>

          <div [style.display]="accordionItem.expanded ? 'block' : 'none'" class="bg-white">
            <div class="flex flex-col bg-white border-t border-t-solid border-t-separator">
              <div class="flex px-4 py-2 items-center w-full border-b border-b-solid border-b-separator bg-gray-100">
                <div class="w-2/4 text-[0.825rem] font-semibold">{{ '#ARTIKELINFO.PAGES.DETAIL.UNITS.UNIT' | translate }}</div>
                <div class="w-1/4 text-[0.825rem] font-semibold flex items-center justify-end text-right">{{ '#ARTIKELINFO.PAGES.DETAIL.UNITS.FACTOR' | translate }}</div>
                <div class="w-1/4 text-[0.825rem] font-semibold flex items-center justify-end text-right">{{ '#ARTIKELINFO.PAGES.DETAIL.UNITS.WEIGHT' | translate }}</div>
              </div>
              <div *ngFor="let unit of (units ?? []) | sort:'factor':'ASC'" class="flex px-4 py-3 items-center w-full border-b border-b-solid border-b-separator">
                <div class="w-2/4">{{ unit?.itemUnit?.name }}<ng-container *ngIf="unit?.itemUnit?.title && unit?.itemUnit?.title !== ''"> - {{ unit?.itemUnit?.title }}</ng-container></div>
                <div class="w-1/4 font-semibold flex items-center justify-end text-right">{{ unit?.factor | number:'1.2-2' }}</div>
                <div class="w-1/4 font-semibold flex items-center justify-end text-right">{{ unit?.weight | number:'1.2-2'  }}</div>
              </div>
            </div>
          </div>
        </cdk-accordion-item>
      </cdk-accordion>

      <cdk-accordion *ngIf="data?.details?.itemSupplier as suppliers">
        <cdk-accordion-item
          role="button"
          tabindex="0"
          #accordionItem="cdkAccordionItem"
          [expanded]="data?.config?.lieferantenExpanded || false"
          [attr.aria-expanded]="accordionItem.expanded"
          class="flex flex-col w-full full-width  border-b border-b-solid border-b-separator">
          <div class="pt-2 pb-1 px-4 font-semibold flex justify-between items-center" (click)="accordionItem.toggle()">
            <h6 class="font-semibold text-light text-sm mb-1 uppercase leading-wide tracking-wide">{{ '#ARTIKELINFO.PAGES.DETAIL.SUPPLIERS.TITLE' | translate }}</h6>
            <div class="text-lighter">
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" class="w-5 h-5 text-light" *ngIf="!accordionItem.expanded">
                <path fill-rule="evenodd" d="M5.23 7.21a.75.75 0 011.06.02L10 11.168l3.71-3.938a.75.75 0 111.08 1.04l-4.25 4.5a.75.75 0 01-1.08 0l-4.25-4.5a.75.75 0 01.02-1.06z" clip-rule="evenodd" />
              </svg>

              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" class="w-5 h-5 text-light" *ngIf="accordionItem.expanded">
                <path fill-rule="evenodd" d="M14.77 12.79a.75.75 0 01-1.06-.02L10 8.832 6.29 12.77a.75.75 0 11-1.08-1.04l4.25-4.5a.75.75 0 011.08 0l4.25 4.5a.75.75 0 01-.02 1.06z" clip-rule="evenodd" />
              </svg>
            </div>
          </div>

          <div [style.display]="accordionItem.expanded ? 'block' : 'none'" class="bg-white">
            <div class="flex flex-col bg-white border-t border-t-solid border-t-separator">
              <div class="flex px-4 py-2 items-end justify-between w-full border-b border-b-solid border-b-separator bg-gray-100">
                <div class="text-[0.825rem] w-6/12 font-semibold flex flex-col">
                  Lieferant
                  <span>Lieferant-ArtNr</span>
                </div>
                <div class="text-[0.825rem] w-4/12 font-semibold flex items-center justify-end text-right">{{ '#ARTIKELINFO.PAGES.DETAIL.SUPPLIERS.MIN_ORDER_AMOUNT' | translate }}</div>
                <div class="text-[0.825rem] w-2/12 font-semibold flex items-center justify-end text-right">{{ '#ARTIKELINFO.PAGES.DETAIL.SUPPLIERS.UNIT' | translate }}</div>
              </div>
              <div *ngFor="let supplier of suppliers"
                   [ngClass]="supplier.supplier.kzliefgelistet === 'H' ? 'bg-green-100' : ''"
                   class="flex px-4 py-3 items-center justify-between w-full border-b border-b-solid border-b-separator">
                <div class="flex flex-col w-6/12">
                  {{ supplier.supplier?.number }} - {{ supplier.supplier?.name }}
                  <span class="text-sm text-light">{{ supplier.foreignNumber }}</span>
                </div>
                <div class="font-semibold flex items-center justify-end text-right w-4/12">{{ supplier.minAmount | number:'1.2-2' }}</div>
                <div class="font-semibold flex text-right items-center justify-end w-2/12">{{ supplier?.itemUnit?.name || supplier?.itemUnit?.title }}</div>
              </div>
            </div>
          </div>
        </cdk-accordion-item>
      </cdk-accordion>
    </ng-container>

    <ng-container *ngIf="(data?.stock) as stock">
      <div class="flex flex-col mt-5" *ngIf="(stock?.orders ||0) > 0">
        <h6 class="font-semibold text-light text-sm mb-1 uppercase leading-wide tracking-wide">{{ '#ARTIKELINFO.PAGES.DETAIL.OPEN_ORDERS.TITLE' | translate }}</h6>
        <div class="full-width flex flex-col bg-white border-t border-t-solid border-t-separator">
          <div class="flex px-4 py-2 items-center justify-between w-full border-b border-b-solid border-b-separator bg-gray-100">
            <div class="text-[0.825rem] font-semibold">{{ '#ARTIKELINFO.PAGES.DETAIL.OPEN_ORDERS.STOCK' | translate }}</div>
            <div class="text-[0.825rem] font-semibold flex items-center justify-end text-right">{{ '#ARTIKELINFO.PAGES.DETAIL.OPEN_ORDERS.OPEN_AMOUNT' | translate }}</div>
          </div>
          <ng-container *ngFor="let stockEntry of stock.stockAmount">
            <div class="flex px-4 py-3 items-center justify-between w-full border-b border-b-solid border-b-separator" *ngIf="stockEntry?.orders && (stockEntry?.orders || 0) > 0">
              <div class="flex items-center gap-2">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.75" stroke="currentColor" class="w-6 h-6 p-0.5 cursor-pointer border border-primary hover:bg-primary-100 text-primary rounded-sm" (click)="showBewegungen(stockEntry, data.item)">
                  <path stroke-linecap="round" stroke-linejoin="round" d="M9 12h3.75M9 15h3.75M9 18h3.75m3 .75H18a2.25 2.25 0 002.25-2.25V6.108c0-1.135-.845-2.098-1.976-2.192a48.424 48.424 0 00-1.123-.08m-5.801 0c-.065.21-.1.433-.1.664 0 .414.336.75.75.75h4.5a.75.75 0 00.75-.75 2.25 2.25 0 00-.1-.664m-5.8 0A2.251 2.251 0 0113.5 2.25H15c1.012 0 1.867.668 2.15 1.586m-5.8 0c-.376.023-.75.05-1.124.08C9.095 4.01 8.25 4.973 8.25 6.108V8.25m0 0H4.875c-.621 0-1.125.504-1.125 1.125v11.25c0 .621.504 1.125 1.125 1.125h9.75c.621 0 1.125-.504 1.125-1.125V9.375c0-.621-.504-1.125-1.125-1.125H8.25zM6.75 12h.008v.008H6.75V12zm0 3h.008v.008H6.75V15zm0 3h.008v.008H6.75V18z" />
                </svg>
                <div>{{ stockEntry.stock?.title || stockEntry.stock?.name }}</div>
              </div>
              <div class="font-semibold flex items-center justify-end text-right">{{ stockEntry.orders | number:'1.2-2' }}</div>
            </div>
          </ng-container>
        </div>
      </div>
    </ng-container>

    <ng-container *ngIf="umsaetze$ | async as umsaetze">
      <div class="flex flex-col mt-5" *ngIf="params?.showUmsatzabfrage">
        <h6 class="font-semibold text-light text-sm mb-0 uppercase tracking-wide">{{ '#ARTIKELINFO.PAGES.DETAIL.UMSATZABFRAGE.TITLE' | translate }}</h6>
        <form class="flex items-center justify-between gap-3 mb-3 mt-2 border border-solid border-separator p-2" [formGroup]="umsatzabfrageForm" (ngSubmit)="onSubmit(data.item)">
          <div class="flex flex-col gap-2 md:flex-row md:gap-3 w-full items-center">
            <soft-date-input formControlName="from" placeholder="Von" class="w-full"></soft-date-input>
            <soft-date-input formControlName="to" placeholder="Bis" class="w-full"></soft-date-input>
            <soft-select formControlName="filiale" placeholder="Filiale" class="w-full">
              <soft-select-option value="Alle">Alle</soft-select-option>
              <soft-select-option [value]="filiale.vkforg"  *ngFor="let filiale of umsaztabfrageFilialen$ | async">
                {{ filiale?.vkforg }}
              </soft-select-option>
            </soft-select>
            <button type="submit" class="soft-button primary w-full md:w-auto md:min-w-[10rem]" [disabled]="umsatzabfrageForm.invalid">{{ '#ARTIKELINFO.PAGES.DETAIL.UMSATZABFRAGE.START' | translate }}</button>
          </div>
        </form>
        <div class="full-width flex flex-col bg-white border-t border-t-solid border-t-separator border-b border-b-solid border-b-separator" *ngIf="item$ | async as item">
          <soft-tab-group [activatedIndex]="(selectedUmsatzabfrageViewIndex$ | async) ?? 0" (activatedIndexChange)="onTabIndexChanged($event || 0, item)">
            <soft-tab [title]="'VKM'"></soft-tab>
            <soft-tab [title]="'VKerl'"></soft-tab>
            <soft-tab [title]="'VKDB%'"></soft-tab>
            <soft-tab [title]="'EK'"></soft-tab>
          </soft-tab-group>
          <div class="flex px-4 py-2 items-center justify-between w-full bg-gray-100">
            <div class="text-[0.825rem] font-semibold w-1/3">{{ '#ARTIKELINFO.PAGES.DETAIL.UMSATZABFRAGE.MONTH' | translate }}</div>
            <div class="flex items-center justify-between w-full w-2/3">
              <div class="text-[0.825rem] font-semibold flex items-center justify-end text-right w-1/2" *ngIf="{ index: (selectedUmsatzabfrageViewIndex$ | async) } as obj">{{ (obj.index === 1 || obj.index === 2) ? ('#ARTIKELINFO.PAGES.DETAIL.UMSATZABFRAGE.VALUE' | translate) : ('#ARTIKELINFO.PAGES.DETAIL.UMSATZABFRAGE.QTY' | translate) }}</div>
              <div class="text-[0.825rem] font-semibold flex items-center justify-end text-right w-1/2">{{ '#ARTIKELINFO.PAGES.DETAIL.UMSATZABFRAGE.PREV_YEAR' | translate }}</div>
            </div>
          </div>
        <div class="flex flex-col bg-white border-t border-t-solid border-t-separator">
          <ng-container *ngIf="(umsaetze?.length ||0) > 0; else noUmsaetzeFoundTemplate">
            <ng-container *ngFor="let umsatz of umsaetze | sortFunction:sortByDate">
              <div class="flex px-4 py-3 items-center justify-between w-full border-b border-b-solid border-b-separator" *ngIf="umsatz">
                <div class="w-1/3">{{ umsatz.date | date:'MMMM' }} {{ umsatz.date | date:'YYYY' }}</div>
                <div class="flex items-center w-2/3" *ngIf="{ selectedView: selectedUmsatzabfrageViewIndex$ | async } as data">
                  <div class="font-semibold flex items-center justify-end text-right w-1/2">{{ (umsatz?.current | umsatzValueForIndex:(data.selectedView || 0)) | number:'1.2-2' }}</div>
                  <div class="font-semibold flex items-center justify-end text-right w-1/2">{{ (umsatz?.vergleich | umsatzValueForIndex:(data.selectedView || 0)) | number:'1.2-2'  }}</div>
                </div>
              </div>
            </ng-container>
            <div class="flex px-4 py-3 items-center justify-between w-full border-b border-b-solid border-b-separator bg-secondary-300" *ngIf="sum$ | async as sum">
              <div class="w-1/3 font-semibold">SUMME</div>
              <div class="flex items-center w-2/3" *ngIf="{ selectedView: selectedUmsatzabfrageViewIndex$ | async } as data">
                <div class="font-semibold flex items-center justify-end text-right w-1/2">{{ (data?.selectedView !== 3 ? (sum?.sum?.current | umsatzValueForIndex:(data.selectedView || 0)) : sum?.ekSum?.current?.mengeEinkauf) | number:'1.2-2' }}</div>
                <div class="font-semibold flex items-center justify-end text-right w-1/2">{{ (data?.selectedView !== 3 ? (sum?.sum?.vergleich | umsatzValueForIndex:(data.selectedView || 0):true) : sum?.ekSum?.vergleich?.mengeEinkauf) | number:'1.2-2'  }}</div>
              </div>
            </div>
          </ng-container>
          <ng-template #noUmsaetzeFoundTemplate>
            <div class="w-full p-5 text-sm text-light text-center flex items-center justify-center">
              {{ umsatzabfrageForm.valid ? 'Es konnten keine Umsätze gefunden werden' : 'Bitte Von- und Bis-Datum eingeben!' }}
            </div>
          </ng-template>
        </div>
      </div>
      </div>
    </ng-container>
  </div>
</div>
