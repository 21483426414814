import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import moment from 'moment';
import { DateService } from '@softline/core';

@Component({
  selector: 'soft-date-navigator',
  templateUrl: './date-navigator.component.html',
  styleUrls: ['./date-navigator.component.scss'],
})
export class DateNavigatorComponent implements OnInit {
  isPickerOpen = false;

  @Input() date: string = this.dateService.today();
  @Output() dateChange = new EventEmitter<string>();

  constructor(private dateService: DateService) {}

  ngOnInit(): void {}

  onChange(date: string, days: number = 0): void {
    const m = moment(date).add(days, 'day');
    this.date = m.toISOString(true);
    this.dateChange.emit(m.toISOString(true));
  }
}
