import { isDefined } from './is-defined.function';
import * as moment from "moment/moment";
import { isEntity } from "../store/specialized/entity/types/entity";

export function equals(first: any, second: any): boolean {
  if (first === second) return true;

  if (!isDefined(first) || !isDefined(second)) return false;

  if (typeof first === 'string' && typeof second === "string") {
    const fDate = moment(first, false);
    const sDate = moment(second, false);
    if(fDate.isValid() && sDate.isValid())
      return fDate.diff(sDate) === 0;
  }

  if (typeof first !== typeof second || typeof first !== 'object') return false;

  if(isEntity(first) && isEntity(second))
    return first.id == second.id;

  if (Array.isArray(first) && Array.isArray(second)) {
    if (first.length !== second.length) return false;
    for (let i = 0; i < first.length; i++) {
      if (!equals(first[i], second[i])) return false;
    }
    return true;
  }

  const keys = new Set([...Object.keys(first), ...Object.keys(second)]);
  for (const key of keys) {
    if (!equals(first[key], second[key])) return false;
  }
  return true;
}
