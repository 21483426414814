import { PortalModule } from "@angular/cdk/portal";
import { CommonModule } from "@angular/common";
import { ModuleWithProviders, NgModule } from "@angular/core";
import { RouterModule } from "@angular/router";
import {
  ConnectionResourceLocation,
  ResourceService,
  SOFTLINE_SERVICE_HTTP, SOFTLINE_STORE_ENTITY_SERVICE,
  SoftlineEntityService,
  Store,
  StoreModule
} from "@softline/core";
import {
  SOFTLINE_CONFIG_PREVIEW_HANDLER,
  SOFTLINE_FEATURE_TRANSLATION,
  TranslationStore,
  UiCoreModule
} from "@softline/ui-core";
import {
  SOFTLINE_CONFIG_DEFAULT_DEBOUNCE_TIME,
  SOFTLINE_CONFIG_SETTINGS,
  SOFTLINE_FEATURE_APP_INFO,
  SOFTLINE_FEATURE_APPLICATIONS,
  SOFTLINE_FEATURE_ARCHIVE,
  SOFTLINE_FEATURE_COMMANDS,
  SOFTLINE_FEATURE_MODULE,
  SOFTLINE_FEATURE_NOTIFICATION,
  SOFTLINE_FEATURE_SETTINGS,
  SOFTLINE_FEATURE_SHELL,
  SOFTLINE_FEATURE_TITLE
} from "./application.shared";
import { ArchiveService } from "./archive/services/archive.service";
import { IsFileDownloadingPipe } from "./pipes/is-file-downloading.pipe";
import { SettingsComponent } from "./settings/components/settings.component";
import { SettingsService } from "./settings/services/settings.service";
import { ShellComponent } from "./shell/components/shell.component";
import { PublicShellComponent } from "./shell/public-shell/components/public-shell.component";
import {
  UploadFileDialogComponent
} from "./archive/components/dialogs/upload-file-dialog/upload-file-dialog.component";
import { ArchiveListComponent } from "./archive/components/archive-list/archive-list.component";
import { DownloadUrlPipe } from "./archive/pipes/download-url.pipe";
import { IsDownloadingPipe } from "./archive/pipes/is-downloading.pipe";
import { CommonAppInfoService } from "./app/services/common-app-info.service";
import { AppInfoService } from "./app/services/app-info.service";
import * as ShellStore from "./shell/shell.store";
import * as AppInfoStore from "./app/app-info.store";
import * as TitleStore from "./program/title.store";
import * as CommandStore from "./program/command.store";
import * as ModuleStore from "./app/module.store";
import * as SettingsStore from "./settings/settings.store";
import * as ArchiveStore from "./archive/archive.store";
import { DashboardComponent } from "./dashboard/components/dashboard.component";
import { ModuleInterceptor } from "./app/interceptors/module.interceptor";
import { AppInfoInterceptor } from "./app/interceptors/app-info.interceptor";
import { HTTP_INTERCEPTORS } from "@angular/common/http";
import { AppInfoSettingsComponent } from "./app/settings/app-info-settings.component";
import { BackNavigationService } from "./program/services/back-navigation.service";
import { RefreshService } from "./program/services/refresh.service";
import { ApplicationStore } from "./app/application.store";
import { default as de } from "../i18n/de.json";
import { ModuleService } from "./app/services/module.service";
import { AddOnService } from "./program/services/add-on.service";
import { ArchiveImagePreviewHandler } from "./archive/services/archive-image-preview.handler";
import { MengenEingabeComponent } from "./components/mengen-eingabe/mengen-eingabe.component";
import { StepHeaderComponent } from "./components/step-header/step-header.component";
import {
  BlockingLoadingSpinnerComponent
} from "./components/blocking-loading-spinner/blocking-loading-spinner.component";
import { NotificationStore } from "./notifications/notifications.store";
import { SOFTLINE_API_NOTIFICATION } from "./application.api";
import { filerAndSortProviders } from "./filter-and-sort/filer-and-sort.providers";
import {AudioService} from './services/audio.service';

@NgModule({
  declarations: [
    IsFileDownloadingPipe,
    UploadFileDialogComponent,
    ArchiveListComponent,
    DownloadUrlPipe,
    IsDownloadingPipe,
    AppInfoSettingsComponent,
  ],
  imports: [
    CommonModule,
    RouterModule,
    UiCoreModule,
    PortalModule,
    ShellComponent,
    PublicShellComponent,
    SettingsComponent,
    MengenEingabeComponent,
    StepHeaderComponent,
    BlockingLoadingSpinnerComponent,
    DashboardComponent,
  ],
  exports: [
    IsFileDownloadingPipe,
    ShellComponent,
    PublicShellComponent,
    ArchiveListComponent,
    SettingsComponent,
    DashboardComponent,
    MengenEingabeComponent,
    StepHeaderComponent,
    BlockingLoadingSpinnerComponent,
  ],
})
export class ApplicationModule {
  static forRoot(): ModuleWithProviders<ApplicationRootModule> {
    return {
      ngModule: ApplicationRootModule,
      providers: [],
    };
  }
}

@NgModule({
  declarations: [],
  imports: [
    ApplicationModule,
    StoreModule.forFeature({
      name: SOFTLINE_FEATURE_SHELL,
      feature: ShellStore.feature,
    }),
    StoreModule.forFeature({
      name: SOFTLINE_FEATURE_SETTINGS,
      feature: SettingsStore.feature,
    }),
    StoreModule.forFeature({
      name: SOFTLINE_FEATURE_APP_INFO,
      feature: AppInfoStore.feature,
    }),
    StoreModule.forFeature({
      name: SOFTLINE_FEATURE_ARCHIVE,
      feature: ArchiveStore.feature,
    }),
    StoreModule.forFeature({
      name: SOFTLINE_FEATURE_TITLE,
      feature: TitleStore.feature,
    }),
    StoreModule.forFeature({
      name: SOFTLINE_FEATURE_MODULE,
      feature: ModuleStore.feature,
    }),
    StoreModule.forFeature({
      name: SOFTLINE_FEATURE_APPLICATIONS,
      feature: ApplicationStore.feature,
    }),
    StoreModule.forFeature({
      name: SOFTLINE_FEATURE_COMMANDS,
      feature: CommandStore.feature,
    }),
    StoreModule.forFeature({
      name: SOFTLINE_FEATURE_NOTIFICATION,
      feature: NotificationStore.feature,
      providers: [{
        provide: SOFTLINE_STORE_ENTITY_SERVICE,
        useFactory: (service: ResourceService<ConnectionResourceLocation>) => new SoftlineEntityService(service, SOFTLINE_API_NOTIFICATION),
        deps: [SOFTLINE_SERVICE_HTTP]
      }]
    }),
  ],
  exports: [ApplicationModule],
  providers: [
    ArchiveService,
    SettingsService,
    DownloadUrlPipe,
    BackNavigationService,
    RefreshService,
    AddOnService,
    ModuleService,
    AudioService,
    ...filerAndSortProviders,
    { provide: AppInfoService, useClass: CommonAppInfoService },
    {
      provide: SOFTLINE_CONFIG_PREVIEW_HANDLER,
      useClass: ArchiveImagePreviewHandler,
      multi: true,
    },
    { provide: HTTP_INTERCEPTORS, useClass: ModuleInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: AppInfoInterceptor, multi: true },
    {
      provide: SOFTLINE_CONFIG_SETTINGS,
      useValue: {
        key: 'appInfo',
        component: AppInfoSettingsComponent,
        title: '#APPLICATION.APP_INFO.SETTINGS',
        icon: 'fas fa-info-circle',
        priority: -1,
      },
      multi: true,
    },
    {provide: SOFTLINE_CONFIG_DEFAULT_DEBOUNCE_TIME, useValue: 200}
  ],
})
export class ApplicationRootModule {
  constructor(store: Store, moduleService: ModuleService) {
    store.dispatch(SOFTLINE_FEATURE_APP_INFO, AppInfoStore.actions.load);
    store.commit(SOFTLINE_FEATURE_TRANSLATION, TranslationStore.mutations.add, {
      module: 'application',
      language: 'de',
      translations: de,
    });
    store.dispatch(SOFTLINE_FEATURE_SETTINGS, SettingsStore.actions.init);
    store.dispatch(SOFTLINE_FEATURE_SETTINGS, SettingsStore.actions.load);
  }
}
