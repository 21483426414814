<ng-container *ngIf="!value">
  <input
    class="mr-2"
    [value]="input || null"
    (input)="onInput(inputComponent.value)"
    [readOnly]="readonly"
    placeholder="{{
      placeholder || '#UI_CORE.COMPONENTS.ENTITY_INPUT.PLACEHOLDER' | translate
    }}"
    #inputComponent
  />
  <ng-container *ngIf="strategy.loading()">
    <i class="fas fa-spinner fa-spin ml-4"></i>
  </ng-container>
  <ng-container *ngIf="strategy.loading() || inputComponent.value.length > 0">
    <div
      class="ml-4 pointer"
      (click)="onCancel(); $event.stopPropagation()"
    >
      <i class="fas fa-times"></i>
    </div>
  </ng-container>
  <ng-container *ngIf="!strategy.loading()">
    <div
      class="ml-4 pointer"
      *ngIf="!readonly"
      (click)="onOpen(); $event.stopPropagation()"
    >
      <i class="fas fa-database"></i>
    </div>
  </ng-container>
</ng-container>
<ng-container *ngIf="value">
  <div class="content-value width-100">
    <ng-container
      *ngTemplateOutlet="entityTemplate; context: $any({ entity: value })"
    ></ng-container>
  </div>
  <div class="pointer order-100 ml-4">
    <i
      class="fas fa-times"
      *ngIf="!readonly"
      (click)="setValue(null)"
    ></i>
  </div>
</ng-container>
